<template>
<v-card class="pa-1">
<div>
      <img src="@/assets/saray.png" class="center" />
    <h4 class="text-center">Get in touch</h4>
    <p class="text-center">Want to get in touch? We'd love to hear from you. Here's how you can reach us...</p>
    
    <div >     
        <div class="container">

            <div style="margin:10px;">
                <a href="tel:96598555759">
                    <div>
                        <img src="@/assets/icons8-phone.svg" style="width:10%;" />
                        <span>+96598555759</span>
                    </div>

                </a>
            </div>


            <div style="margin:10px;">
                <a target="_blank" href="https://wa.me/96598555759'">
                    <img src="@/assets/icons8-whatsapp.svg" style="width:10%;" />
                    <span>+96598555759</span>
                </a>
            </div>

            <div style="margin:10px;">
                <a target="_blank" href="mailto:Support@sarayperfumes.com'">
                    <img src="@/assets/icons8-email-64.png" style="width:10%;" />
                    <span>Support@sarayperfumes.com</span>
                </a>
            </div>

        </div>
    </div>

  </div>
</v-card>
  
</template>

<script>
export default {
name:"ContactUsComponent",

}
</script>

  <style scoped>

        /* Add some padding inside the card container */
        .container {
            padding: 15px 16px;
        }



        /* Add rounded corners to the top left and the top right corner of the image */
        img {
            border-radius: 5px 5px 0 0;
        }

        .phonediv {
            font-size: 16px;
            color: darkgray;
            text-decoration: none;
        }

        a:hover {
            color: #00A0C6;
            text-decoration: none;
            cursor: pointer;
        }
        a {
            text-decoration: none;
        }
        a span {
            vertical-align: top;
        }

        .phonenumber {
            vertical-align: top;
        }

        .center {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 40%;
        }
    </style>