<template>
  <v-app>
    <v-container v-if="isSplash" fill-height>
      <v-row class="align-center">
        <v-img
          v-if="isSplash"
          contain
          transition="scale-transition"
          src="@/assets/saray.png"
          height="300"
          width="300"
         
        />
      </v-row>
    </v-container>
    <div v-else>
      <v-app-bar color="white" app elevate-on-scroll>
        <v-img
           @click="goToHome"
          alt="Saray Logo"
          class="shrink mr-2"
          contain
          src="@/assets/square.png"
          transition="scale-transition"
          width="40"
          height="40"
        />
         <v-app-bar-nav-icon color="black" @click.stop="showMenu = !showMenu"></v-app-bar-nav-icon>

        <SearchComponent />

        <v-spacer></v-spacer>
        <v-btn color="black" @click="showCart = !showCart" icon>
           <v-badge
            color="red"
            left
            overlap
            :content="cart.lineItems.length"
            :value="cart.lineItems.length > 0"
          ><v-icon>mdi-shopping</v-icon></v-badge>
          
        </v-btn>
       <!--  <v-btn color="black" icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn> -->
        <v-btn @click="accountDialog = !accountDialog" color="black" icon>
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer temporary app v-model="showMenu" :right="isRight()">
        <template v-slot:prepend>
          <NavDrawerHeaderComponent/>
        </template>
        <NavDrawerComponent />
        <template v-slot:append>
          <div></div>
        </template>
      </v-navigation-drawer>

      <v-navigation-drawer temporary app v-model="showCart" :right="!isRight()">
        <template v-slot:prepend>
          <CartHeaderComponent/>
        </template>
        <CartComponent />
        <template v-slot:append>
         <CartFooterComponent />
         <br>
         <br>
         <br>
        </template>
      </v-navigation-drawer>
      <v-main>
        <router-view :key="$route.fullPath" />
      </v-main>
    </div>

    <v-dialog  v-model="accountDialog"  width="500">
      <AccountComponent />
    </v-dialog>

 
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import SearchComponent from "@/components/SearchComponent";
import CartComponent from "@/components/CartComponent";
import CartFooterComponent from "@/components/CartFooterComponent";
import CartHeaderComponent from "@/components/CartHeaderComponent";
import NavDrawerComponent from "@/components/NavDrawerComponent";
import NavDrawerHeaderComponent from "@/components/NavDrawerHeaderComponent";
import AccountComponent from "@/components/AccountComponent";



export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Saray Perfumes",
  },

  components: {
    SearchComponent,
    CartComponent,
    CartFooterComponent,
    CartHeaderComponent,
    NavDrawerComponent,
    NavDrawerHeaderComponent,
    AccountComponent,
    
  },

  //********************** */
  data: () => ({
    accountDialog: false,
    showMenu: false,
    drawer: false,
    isLoggingOut: false,
    isSplash: true,
    logOutDialog: false,
    showCart: false,
    localizedText: {
      en: {
       
      },
      ar: {
   
      },
    },
  }),

  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
    }),
    ...mapGetters("Cart", {
      cart: "cart",
    }),
  },
  //********************** */
  methods: {
    goToHome: function(){
       this.$router.push({ path: `/` });
    },
    loadCart: async function () {
      await this.$store.dispatch("Cart/loadCart", this.language);
      await this.$store.dispatch("Wallet/loadWallet", this.language);
    },
    loadCatalog: async function () {
      await this.$store.dispatch("Catalog/loadCatalog", this.language);
    },

    signUpAnonymous: async function () {
      await this.$store.dispatch("Account/signUpAnonymous");
    },

    langaugeChanged: async function (language) {
      if (language !== this.language) {
        await this.$store.dispatch("Account/changeLanguage", language);
        //this.$i18n.locale = this.language;
        if (this.language === "en") {
          this.$vuetify.rtl = false;
        } else {
          this.$vuetify.rtl = true;
        }
        //await this.loadCatalog();
        //this.$emit("currency-selected");
      }
    },
    validateCartStock: function(){
        this.$store.dispatch("Cart/validateCartStock");
    },
    isRight: function () {
      return this.language === "ar";
    },

    logOut: async function () {
      this.isLoggingOut = true;
      this.logOutDialog = false;
      try {
        this.$store.dispatch("Account/logOut");
        await this.$store.dispatch("Account/signUpAnonymous");
        this.$store.dispatch("loadToken");
      } catch (error) {
        this.showError = true;
        setTimeout(() => (this.showError = false), 3000);
        alert(error);
      }
      this.isLoggingOut = false;
    },
  },

  //********************** */

  async mounted() {
     if (this.language === "en") {
        this.$vuetify.rtl = false;
      } else {
        this.$vuetify.rtl = true;
      }
      
    let self = this;
    setTimeout(function () {
      self.isSplash = false;
    }, 1500);

    this.$store.dispatch("loadToken");

    if (this.isLoggedIn) {
      await this.loadCart();
    } else {
      await this.signUpAnonymous();
      this.$store.dispatch("loadToken");
      await this.loadCart();
    }

    await this.loadCatalog();

    this.validateCartStock(); 
  },

  created() {
    //this.$i18n.locale = this.language;
    if (this.language === "en") {
      this.$vuetify.rtl = false;
    } else {
      this.$vuetify.rtl = true;
    }
  },
};
</script>
