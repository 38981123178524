<template>
  <div class="pa-2">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ localizedText[language].total }}
          {{ displayTotal() }}</v-list-item-title>
        <v-list-item-subtitle v-if="countryCode != 'KW'">
          {{ localizedText[language].shippingCost }}
          {{ displayShippingCosts() }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-btn v-if="cart.totalAfterDiscount >= 20" @click="checkout()" dark block>
      {{ localizedText[language].checkout }}
    </v-btn>
    <v-list-item v-else>
      <v-list-item-content>
        <v-list-item-subtitle class="red--text">
          {{ localizedText[language].minimumOrder }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CartFooterComponent",
  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currency: "currency",
      countryCode: "countryCode",
    }),
    ...mapGetters("Cart", {
      cart: "cart",
    }),
  },

  //********************** */
  data: () => ({
    localizedText: {
      en: {
        checkout: "Checkout",
        total: "Total: ",
        shippingCost: "Shipping cost: ",
        minimumOrder: "Minimum order is 20 KD",
      },
      ar: {
        checkout: "اتمام الشراء",
        total: "المجموع: ",
        shippingCost: "تكاليف الشحن: ",
        minimumOrder: "أقل قيمة للطلب 20 دك",
      },
    },
  }),

  methods: {
    displayTotal: function () {
      let currencySymbol = this.currency.symbol;
      let currencyCode = this.currency.currencyCode;

      let totalForCountries = this.cart.totalForCountries;

      let totalAfterDiscount =
        this.cart.totalAfterDiscount * this.currency.value;

      let totalForCountry = Object.prototype.hasOwnProperty.call(
        totalForCountries,
        currencyCode
      )
        ? totalForCountries[currencyCode]
        : totalAfterDiscount;

      return `${totalForCountry} ${currencySymbol}`;
    },

    displayShippingCosts: function () {
      let currencySymbol = this.currency.symbol;

      let shippingCost = this.cart.calculatedShippingCost * this.currency.value;

      let calculatedShippingCostForCountries =
        this.cart.calculatedShippingCostForCountries;

      let shippingCostForCountry = Object.prototype.hasOwnProperty.call(
        calculatedShippingCostForCountries,
        this.currencyCode
      )
        ? calculatedShippingCostForCountries[this.currencyCode]
        : shippingCost;

      return `${shippingCostForCountry} ${currencySymbol}`;
    },

    checkout: function () {
     // this.$router.push({ path: `/checkout` });
      this.$router.push({ path: `/checkout` }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          // log and throw the error if it's not the one we expect
          console.error(err)
          throw err
        }
      })

    },
  },
};
</script>

<style></style>