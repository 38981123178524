import { appBaseUrl } from "@/constants"

import { CatalogClient } from "@/lib/api"


const initialState = () => ({
    api: new CatalogClient(appBaseUrl),
    catalog: JSON.parse(window.localStorage.getItem('catalog')) || {version : 0},
    countries: JSON.parse(window.localStorage.getItem('countries')) || [],
    homeBanner: JSON.parse(window.localStorage.getItem('homeBanner')) || {
        bannarItems:[]
    },
    mainPageCategories:  JSON.parse(window.localStorage.getItem('mainPageCategories')) || [],
    homePageCategories: JSON.parse(window.localStorage.getItem('homePageCategories')) || [],
    kuwaitAreas: JSON.parse(window.localStorage.getItem('kuwaitAreas')) || [],
    paymentMethods:  [],
    deliverySpeedOptions: [],
    categoryItems:{categoryName:"", categoryOutlineId:"",items:[]},
    currentItem:null
});

// State object
const state = {
    api: new CatalogClient(appBaseUrl),
    catalog: JSON.parse(window.localStorage.getItem('catalog')) || {version : 0},
    countries: JSON.parse(window.localStorage.getItem('countries')) || [],
    homeBanner: JSON.parse(window.localStorage.getItem('homeBanner')) || {
        bannarItems:[]
    },
    mainPageCategories: JSON.parse(window.localStorage.getItem('mainPageCategories')) || [],
    homePageCategories: JSON.parse(window.localStorage.getItem('homePageCategories')) || [],
    kuwaitAreas: JSON.parse(window.localStorage.getItem('kuwaitAreas')) || [],
    paymentMethods:  [],
    deliverySpeedOptions: [],
    categoryItems:{categoryName:"", categoryOutlineId:"",items:[]},
    currentItem:null,
}

// Getter functions
const getters = {
    getApi(state) {
        return state.api;
    },
    catalog(state) {
        return state.catalog;
    },
    countries(state) {
        return state.countries;
    },
    homeBanner(state) {
        return state.homeBanner;
    },
    mainPageCategories(state) {
        return state.mainPageCategories;
    },
    homePageCategories(state) {
        return state.homePageCategories;
    },
    kuwaitAreas(state) {
        return state.kuwaitAreas;
    },
    paymentMethods(state) {
        return state.paymentMethods;
    },
    deliverySpeedOptions(state) {
        return state.deliverySpeedOptions;
    },
    categoryItems(state){
        return state.categoryItems;
    },
    currentItem(state){
        return state.currentItem;
    }

}

// Actions 
const actions = {

   async loadCatalog({commit, state}, language){

       let currentVersion = state.catalog.version;

       const response = await state.api.latestFullCatalog(currentVersion, language, "web");

        commit('loadCatalogCompleted', response);
    },

    async freshLoadCatalog({commit, state}, language){

        state.catalog.version = 1;
        const response = await state.api.latestFullCatalog(1, language, "web");
 
         commit('loadCatalogCompleted', response);
     },
    
    async loadCountries({commit, state}){

        const response = await state.api.countries();

        commit('loadCountriesCompleted', response);
    },

    async loadHomeBanner({commit, state}, language){

        const response = await state.api.homeBanner(language)

        commit('loadHomeBannerCompleted', response);
    },

    async loadMainPageCategories({commit, state}, language){

        const response = await state.api.mainPageCategories(language);

        commit('loadMainPageCategoriesCompleted', response);
    },

    async loadHomePageCategories({commit, state}, {countryCode, currencyCode, language}){
        
        const response = await state.api.homePageCategories(countryCode, currencyCode,language);

        commit('loadHomePageCategoriesCompleted', response);
    },

    async loadKuwaitAreas({commit, state}){

        const response = await state.api.allKuwaitAreas();

        commit('loadKuwaitAreasCompleted', response);
    },

    async loadPaymentMethods({commit, state}, countryCode){

        const response = await state.api.paymentMethods(countryCode);

        commit('loadPaymentMethodsCompleted', response);
    },

    async loadDeliverySpeedOptions({commit, state}){

        const response = await state.api.deliverySpeedOptions();

        commit('loadDeliverySpeedOptionsCompleted', response);
    },

    async loadCategoryItems({commit, state}, {outlineId, countryCode, currencyCode, language}){

        commit('loadCategoryItemsCompleted', []);

        const response = await state.api.categoryItems(outlineId, countryCode, currencyCode, language, "web");
        //let categoryItems = state.catalog.items.filter((i) => i.categoryId == categoryId);
        commit('loadCategoryItemsCompleted', response);
    },

    async loadCurrentItem({commit, state}, {itemId, countryCode, currencyCode, language}){

        commit('loadCurrentItemCompleted', null);

        const response = await state.api.itemDetail(itemId, countryCode, currencyCode, language);

        commit('loadCurrentItemCompleted', response);

    },

    async loadItem({state},{itemId, countryCode, currencyCode, language}){
        
         const response = await state.api.itemDetail(itemId, countryCode, currencyCode, language);
         return response;
    }

   
    
}

// Mutations
const mutations = {

    loadCatalogCompleted(state, catalog){
        
        if(catalog.version > state.catalog.version){
            window.localStorage.setItem('catalog', JSON.stringify(catalog));
            state.catalog = catalog;
        }

    },

    loadCountriesCompleted(state, countries){
        window.localStorage.setItem('countries', JSON.stringify(countries));
        state.countries = countries;
    },

    loadHomeBannerCompleted(state, homeBanner){
        window.localStorage.setItem('homeBanner', JSON.stringify(homeBanner));
        state.homeBanner = homeBanner;
    },

    loadMainPageCategoriesCompleted(state, categories){
        window.localStorage.setItem('mainPageCategories', JSON.stringify(categories));
        state.mainPageCategories = categories;
    },

    loadHomePageCategoriesCompleted(state, categories){
        window.localStorage.setItem('homePageCategories', JSON.stringify(categories));
        state.homePageCategories = categories;
    },

    loadKuwaitAreasCompleted(state, kuwaitAreas){
        window.localStorage.setItem('kuwaitAreas', JSON.stringify(kuwaitAreas));
        state.kuwaitAreas = kuwaitAreas;
    },

    loadPaymentMethodsCompleted(state, paymentMethods){
        state.paymentMethods = paymentMethods;
    },
    loadDeliverySpeedOptionsCompleted(state, options){
        state.deliverySpeedOptions = options;
    },
    loadCategoryItemsCompleted(state, categoryItems){
        state.categoryItems = categoryItems;
    },

    loadCurrentItemCompleted(state, item){
        state.currentItem = item;
    },


    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        });
    },
    loadToken(state){
        let token = window.localStorage.getItem('token') || ""
        state.api.instance.defaults.headers.common = {'Authorization': `Bearer ${token}`};
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}