import { appBaseUrl } from "@/constants"

import { WalletClient } from "../../lib/api";


const initialState = () => ({
    api: new WalletClient(appBaseUrl),
    wallet:{},
    giftCardOrderPaymentResult:{}

});

// State object
const state = {
    api: new WalletClient(appBaseUrl),
    wallet:{},
    giftCardOrderPaymentResult:{}
   
}

// Getter functions
const getters = {
    getApi(state) {
        return state.api;
    },

    wallet(state) {
        return state.wallet;
    },

    giftCardOrderPaymentResult(state){
        return state.giftCardOrderPaymentResult;
    }

    
    

}

// Actions 
const actions = {

    async loadWallet({commit, state}, language){

        const response = await state.api.myWallet(language)

        commit("loadWalletCompleted", response);
    } ,

    async placeGiftCardOrder({commit, state}, {receiverEmail, paymentMethodId, giftCardId, message, language}){


        let model = {
            receiverEmail, paymentMethodId, giftCardId, message, language
        }

        const response = await state.api.placeGiftCardOrder(model)

        commit("placeGiftCardOrderCompleted", response);
    } ,

}

// Mutations
const mutations = {

    loadWalletCompleted(state, wallet){
        state.wallet = wallet;
    },
    placeGiftCardOrderCompleted(state, paymentResult){

        state.giftCardOrderPaymentResult = paymentResult;

    },

    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        });
    },
    loadToken(state){
        let token = window.localStorage.getItem('token') || ""
        state.api.instance.defaults.headers.common = {'Authorization': `Bearer ${token}`};
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}