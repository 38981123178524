import { appBaseUrl } from "@/constants"

import { BookingClient } from "@/lib/api"


const initialState = () => ({
    api: new BookingClient(appBaseUrl),
    bookings:[],
    bookingPaymentResult:{}

});

// State object
const state = {
    api: new BookingClient(appBaseUrl),
    bookingPaymentResult:{}
}

// Getter functions
const getters = {
    getApi(state) {
        return state.api;
    },
    bookings(state) {
        return state.bookings;
    },
    bookingPaymentResult(state){
        return state.bookingPaymentResult;
    }
    
    

}

// Actions 
const actions = {

    ///api/Booking/CustomerBookings
    async loadBookings({commit, state}, language){

        const response = await state.api.customerBookings(language);

        commit("loadBookingsCompleted", response);
    } ,

    ///api/Booking/CreateBooking

    async createBooking({commit, state}, {itemId, address, options, paymentMethodId, bookingDate, additionalHours, language, bookingDay, bookingTime, bookingType }){

        let model = {itemId, address, options, paymentMethodId, bookingDate, additionalHours, language, bookingDay, bookingTime, bookingType };

        const response = await state.api.createBooking(model);

        commit("createBookingCompleted", response);
        
    }

    
}

// Mutations
const mutations = {

    loadBookingsCompleted(state, bookings){
        state.bookings = bookings;
    },
    
    createBookingCompleted(state, paymentResult){
        state.bookingPaymentResult = paymentResult
    },

    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        });
    },
    loadToken(state){
        let token = window.localStorage.getItem('token') || ""
        state.api.instance.defaults.headers.common = {'Authorization': `Bearer ${token}`};
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}