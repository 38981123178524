<template>
 <div>
   <HomePageComponent></HomePageComponent>
 </div>
</template>

<script>
 import HomePageComponent from "@/components/HomePageComponent";

  export default {
    name: 'Home',

    components: {
       HomePageComponent
    },
  }
</script>
