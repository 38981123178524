import { appBaseUrl } from "@/constants";

import { AddressClient } from "@/lib/api";

const initialState = () => ({
  api: new AddressClient(appBaseUrl),
  addresses: [],
  allAddresses: [],
});

// State object
const state = {
  api: new AddressClient(appBaseUrl),
  addresses: [],
  allAddresses: [],
};

// Getter functions
const getters = {
  getApi(state) {
    return state.api;
  },
  addresses(state) {
    return state.addresses;
  },
  allAddresses(state) {
    return state.allAddresses;
  },
};

// Actions
const actions = {
  async loadAddresses({ commit, state }) {
    const response = await state.api.addresses();

    commit("loadAddressesCompleted", response);
  },

  async loadAllAddresses({ commit, state }) {
    const response = await state.api.allAddresses();

    commit("loadAllAddressesCompleted", response);
  },

  async createAddress(
    { dispatch, state },
    {
      addressType,
      firstName,
      middleName,
      lastName,
      email,
      countryCode,
      country,
      town,
      area,
      postalCode,
      phone,
      secondPhoneNumber,
      government,
      block,
      avenue,
      street,
      house,
      flat,
      floor,
      building,
      office,
      line1,
      line2,
      deliveryInstructions,
      isGift,
      isGiftAndAddressUnkown,
      phoneOfGiftRecipient,
      nameOfGiftRecipient,
      language,
    }
  ) {
    let model = {
      addressType,
      firstName,
      middleName,
      lastName,
      email,
      countryCode,
      country,
      town,
      area,
      postalCode,
      phone,
      secondPhoneNumber,
      government,
      block,
      avenue,
      street,
      house,
      flat,
      floor,
      building,
      office,
      line1,
      line2,
      isGift,
      isGiftAndAddressUnkown,
      phoneOfGiftRecipient,
      nameOfGiftRecipient,
      deliveryInstructions,
      language,
    };

    await state.api.createAddress(model);

    await dispatch("loadAddresses");

    await dispatch("loadAllAddresses");
  },

  async deleteAddresses({ dispatch, state }, { addressId, language }) {
    await state.api.deleteAddress(addressId, language);

    await dispatch("loadAddresses");

    await dispatch("loadAllAddresses");
  },

  async updateAddress(
    { dispatch, state },
    {
      id,
      addressType,
      firstName,
      middleName,
      lastName,
      email,
      countryCode,
      country,
      town,
      area,
      postalCode,
      phone,
      secondPhone,
      government,
      block,
      avenue,
      street,
      house,
      flat,
      floor,
      building,
      office,
      deliveryInstructions,
      language,
    }
  ) {
    let model = {
      addressId:id,
      addressType,
      firstName,
      middleName,
      lastName,
      email,
      countryCode,
      country,
      town,
      area,
      postalCode,
      phone,
      secondPhoneNumber:secondPhone,
      government,
      block,
      avenue,
      street,
      house,
      flat,
      floor,
      building,
      office,
      deliveryInstructions,
      language,
    };

    await state.api.updateAddress(model);

    await dispatch("loadAddresses");

    await dispatch("loadAllAddresses");
  },
};

// Mutations
const mutations = {
  loadAddressesCompleted(state, addresses) {
    state.addresses = addresses;
  },

  loadAllAddressesCompleted(state, addresses) {
    state.allAddresses = addresses;
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  loadToken(state) {
    let token = window.localStorage.getItem("token") || "";
    state.api.instance.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
