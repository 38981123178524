<template>
  <div>
    <v-card class="ma-1" min-width="120" :max-width="maxWidth">
      <v-img
        class="rounded-lg"
        @click="openItemDetail"
        height="120"
        contain
        :src="item.mainThumbnailUrl"
      >
      </v-img>

      <v-card-text>
        <div>{{ item.categoryName }}</div>
        <div class="black--text">{{ item.name }}</div>
        <div>
          <div>
            <span :class="actualPriceClass">{{ displayActualPrice() }}</span>
            <span
              v-if="
                item.hasPromotion &&
                item.activePromotion.promotionType == 'Discount'
              "
              class="original-price"
              >{{ displayUnitPrice() }}</span
            >

            <span
              v-if="
                item.hasPromotion &&
                item.activePromotion.promotionType == 'FreeGiftFromList'
              "
              class="indigo--text"
            >
              [{{ localizedText[language].withGift }}]
            </span>
          </div>

          <v-btn
            elevation="0"
            
            block
            v-if="
            this.item.itemType != 'Package' &&
              this.item.itemType != 'GiveawayService' &&
              this.item.itemType != 'IncensingPerfumingService'
            "
            :loading="isAddingToCart"
            class="ma-1 white--text" color="black"
            @click="addToCart"
            :disabled="!this.item.isInStock"
          >
            <!-- <v-icon>mdi-shopping</v-icon> -->
            {{  item.isInStock ? localizedText[language].addToCart : localizedText[language].outOfStock }}
          </v-btn>

          <v-btn
          :disabled="!this.item.isInStock"
            elevation="0"
           
            block
            v-if="
            this.item.itemType == 'Package'
            "
            class="ma-1 white--text" color="black"
            @click="openItemDetail"
          >
            <!-- <v-icon>mdi-shopping</v-icon> -->
            {{ item.isInStock ? localizedText[language].viewDetails : localizedText[language].outOfStock }}
          </v-btn>
          <v-btn
            v-if="this.item.itemType == 'IncensingPerfumingService'"
            class="ml-auto"
            icon
            @click="bookNow"
            ><v-icon>mdi-calendar</v-icon></v-btn
          >
        </div>
      </v-card-text>
   
    </v-card>

    <v-dialog width="310" v-model="giftDialog">
      <v-card
      :loading="isLoadingFreeGifts"
        v-if="
          item &&
          item.hasPromotion &&
          item.activePromotion.promotionType == 'FreeGiftFromList'
        "
      >
        <v-card-title>
          {{ localizedText[language].freeGift }}
        </v-card-title>
        
        <v-divider></v-divider>

          <v-list flat>
            <v-list-item-group v-model="selectedGift">
              <v-list-item
                v-for="currentItem in freeGiftItems"
                :key="currentItem.id"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-avatar>
                    <v-img :src="currentItem.smallThumbnailUrl"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="currentItem.name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
      
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                outlined
                depressed
                color="black"
                dark
                @click="cancel"
                block
              >
                {{ localizedText[language].cancel }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :dark="selectedGift != undefined"
                color="black"
                :disabled="selectedGift == undefined"
                @click="done"
                block
              >
                {{ localizedText[language].done }}
              </v-btn></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ItemComponent",

  components: {},

  //********************** */
  data: () => ({
    freeGiftItems:[],
    isAddingToCart: false,
    isLoadingFreeGifts: false,
     giftDialog: false,
    selectedGift: undefined,
    localizedText: {
      en: {
        priceOnSelection: "Price on selection",
        withGift: " + Free Gift ",
        addToCart: "Add To Cart",
                cancel: "Cancel",
        done: "Done",
        freeGift: "Free Gift",
        viewDetails: "View Details",
        outOfStock: "Out of Stock",
      },
      ar: {
        priceOnSelection: "السعر عند الاختيار",
        withGift: " + هدية مجانية ",
        addToCart: "أضف إلى السلة",
           cancel: "الغاء",
        done: "تم",
        freeGift: "هدية مجانية",
        viewDetails: "عرض التفاصيل",
        outOfStock: "نفذت الكمية",
      },
    },
  }),

  //********************** */
  props: {
    item: {
      type: Object,
    },
    promotions: {
      type: Array,
    },
    maxWidth: {
      type: Number,
      default: 300,
    },
  },

  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
      countryCode: "countryCode",
    }),

    actualPriceClass: function () {
      if (
        this.promotions &&
        this.item.promotions.length > 0 &&
        this.item.promotions[0].promotionType == "Discount"
      ) {
        return "red--text";
      } else {
        return "black--text";
      }
    },

    activatePromotion: function () {
      return this.item.activatePromotion;
    },
  },
  //********************** */
  methods: {
    bookNow: function () {
      alert("Book Now");
    },
    addToCart: async function () {

       if (
        this.item.hasPromotion &&
        this.item.activePromotion.promotionType == "FreeGiftFromList"
      ) {
        this.giftDialog = true;
        await this.loadFreeItems();
        return;
      }

      this.isAddingToCart = true;

      if (this.item.itemType == "MultiPrice") {
        let specialModel = {
          quantity: 1,
          itemId: this.item.id,
          language: this.language,
        };

        try {
          await this.$store.dispatch(
            "Cart/addItemWithSpecialPromotion",
            specialModel
          );
          window.snaptr('track', 'ADD_CART');
        } catch (error) {
          console.log(error);
        }
        this.isAddingToCart = false;
        return;
      }

      let model = {
        quantity: 1,
        itemId: this.item.id,
        language: this.language,
      };

      try {
        await this.$store.dispatch("Cart/addItem", model);
        window.snaptr('track', 'ADD_CART');
      } catch (error) {
        console.log(error);
      }

      this.isAddingToCart = false;
    },
    openItemDetail: function () {
      if (this.item.itemType == "GiveawayService") {
        this.$router.push({ path: `/giveawayitem/${this.item.id}` });
      } else if (this.item.itemType == "IncensingPerfumingService") {
        console.log("IncensingPerfumingService");
      } else {
        this.$router.push({ path: `/item/${this.item.id}` });
      }
    },

    displayActualPrice: function () {
      if (this.item.itemType == "GiveawayService") {
        return this.localizedText[this.language].priceOnSelection;
      }

      //target.hasOwnProperty(name) ? target[name] : 42;
      /*       let unitPriceForCountries = this.item.unitPriceForCountries;

      let unitPriceForCountry = Object.prototype.hasOwnProperty.call(
        unitPriceForCountries,
        this.currencyCode
      )
        ? unitPriceForCountries[this.currencyCode]
        : 0;

      if (unitPriceForCountry > 0) {
        if (this.item.promotions && this.item.promotions.length > 0) {
          let best = this.item.promotions.sort(
            (a, b) => a.quantity > b.quantity
          )[0];
          if (best.promotionType == "Discount") {
            let discountPrice = Object.prototype.hasOwnProperty.call(
              best.discountPriceForCountries,
              this.currencyCode
            )
              ? best.discountPriceForCountries[this.currencyCode]
              : best.discountPrice * this.currency.value;

            return `${discountPrice} ${this.currency.symbol}`;
          }
        }

        return `${unitPriceForCountry} ${this.currency.symbol}`;
      }

      if (this.item.promotions && this.item.promotions.length > 0) {
        let best = this.item.promotions.sort(
          (a, b) => a.quantity > b.quantity
        )[0];
        if (best.promotionType == "Discount") {
          return `${best.discountPrice * this.currency.value} ${
            this.currency.symbol
          }`;
        }
      } */

      //console.log(this.item.calculatedActualPrice);

      return `${this.item.calculatedActualPrice} ${this.currency.symbol}`;
    },
    displayUnitPrice: function () {
      return `${this.item.calculatedUnitPrice} ${this.currency.symbol}`;
      /*  let unitPriceForCountries = this.item.unitPriceForCountries;

      let unitPriceForCountry = Object.prototype.hasOwnProperty.call(
        unitPriceForCountries,
        this.currencyCode
      )
        ? unitPriceForCountries[this.currencyCode]
        : 0;

      if (unitPriceForCountry > 0.0) {
        return `${unitPriceForCountry} ${this.currency.symbol}`;
      }

      let price = this.item.unitPrice * this.currency.value;

      return `${price} ${this.currency.symbol}`; */
    },

    loadFreeItems: async function(){

      let itemId = this.item.id;
      let countryCode = this.countryCode;
      let currencyCode = this.currencyCode;
      let language = this.language;

      try {
        this.isLoadingFreeGifts = true;
        let loadedItem = await this.$store.dispatch("Catalog/loadItem", {
          itemId,
          countryCode,
          currencyCode,
          language,
        });
        this.freeGiftItems = loadedItem.activePromotion.freeGiftItems;
        this.isLoadingFreeGifts = false;
      } catch (error) {
        this.isLoadingFreeGifts = false;
        alert(error);
      }

    },

      done: async function () {
      
      this.giftDialog = false;
       this.isAddingToCart = true;
       
       let giftItem = this.freeGiftItems[this.selectedGift];
      let model = {
        quantity: 1,
        itemId: this.item.id,
        language: this.language,
        withPlate: false,
        withCard: false,
        plateText: null,
        cardText: null,
        giftItemId: giftItem.id
      };

      try {
        await this.$store.dispatch("Cart/addItemWithGift", model);
        window.snaptr('track', 'ADD_CART');
      } catch (error) {
        console.log(error);
      }

      this.isAddingToCart = false;
    },

    cancel: function () {
      this.giftDialog = false;
      this.selectedGift = undefined;
    },
  },

  //********************** */

  async mounted() {},

  created() {},
};
</script>

<style>
original-price {
  text-decoration: line-through;
}
</style>