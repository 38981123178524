<template>
  <div>
    <v-card>
      <v-card-title>{{ localizedText[language].chooseLanguage }}</v-card-title>
      <v-card-text>
        <v-btn-toggle dark v-model="selectedLanguage">
          <v-btn value="en"> English </v-btn>

          <v-btn value="ar"> العربية </v-btn>
        </v-btn-toggle>
      </v-card-text>

      <v-card-actions >
        <v-btn :loading="isLoading"  @click="save" :disabled="selectedLanguage == language" dark block>{{ localizedText[language].save }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LanguagePickerComponent",
  data: () => ({
    selectedLanguage: "en",
    isLoading: false,
    localizedText: {
      en: {
        chooseLanguage: "Choose language",
        save: "Save",
      },
      ar: {
        chooseLanguage: "اختر اللغة",
        save: "حفظ",
      },
    },
  }),
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
    }),
  },

  methods:{

      save: async function(){
          this.isLoading = true;
           await this.$store.dispatch("Account/changeLanguage", this.selectedLanguage);
           this.isLoading = false;
           this.$emit('language-selected');
      }
  },




  mounted() {
    this.selectedLanguage = this.language;
  },
};
</script>

<style>
</style>