<template>
  <div>
      <v-card flat class="ma-2"></v-card>
     <!--  <div v-if="isRegistered"></div>
      <div v-else>
         
      </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
name:"NavDrawerHeaderComponent",
 //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
    }),

  },
   data: () => ({
    localizedText: {
      en: {
       signInMessage:"To keep the information about your orders and addresses please sign in or sign up."
      },
      ar: {
       signInMessage:"للاحتفاظ بمعلوماتك الخاصة بالطلبات و سجل العناوين يرجى تسجيل الدخول."
      },
    },
  }),
}
</script>

<style>

</style>