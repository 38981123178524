<template>
  <div class="pa-2">
    <div class="text-center">{{ localizedText[language].shoppingBag }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CartHeaderComponent",
  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
    }),
  },

  //********************** */
  data: () => ({
    localizedText: {
      en: {
        shoppingBag: "Shopping bag",
      },
      ar: {
        shoppingBag: "سلة المشتريات",
      },
    },
  }),
};
</script>

<style>
</style>