var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1",attrs:{"min-width":"120","max-width":_vm.maxWidth}},[_c('v-img',{staticClass:"rounded-lg",attrs:{"height":"120","contain":"","src":_vm.item.mainThumbnailUrl},on:{"click":_vm.openItemDetail}}),_c('v-card-text',[_c('div',[_vm._v(_vm._s(_vm.item.categoryName))]),_c('div',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.item.name))]),_c('div',[_c('div',[_c('span',{class:_vm.actualPriceClass},[_vm._v(_vm._s(_vm.displayActualPrice()))]),(
              _vm.item.hasPromotion &&
              _vm.item.activePromotion.promotionType == 'Discount'
            )?_c('span',{staticClass:"original-price"},[_vm._v(_vm._s(_vm.displayUnitPrice()))]):_vm._e(),(
              _vm.item.hasPromotion &&
              _vm.item.activePromotion.promotionType == 'FreeGiftFromList'
            )?_c('span',{staticClass:"indigo--text"},[_vm._v(" ["+_vm._s(_vm.localizedText[_vm.language].withGift)+"] ")]):_vm._e()]),(
          this.item.itemType != 'Package' &&
            this.item.itemType != 'GiveawayService' &&
            this.item.itemType != 'IncensingPerfumingService'
          )?_c('v-btn',{staticClass:"ma-1 white--text",attrs:{"elevation":"0","block":"","loading":_vm.isAddingToCart,"color":"black","disabled":!this.item.isInStock},on:{"click":_vm.addToCart}},[_vm._v(" "+_vm._s(_vm.item.isInStock ? _vm.localizedText[_vm.language].addToCart : _vm.localizedText[_vm.language].outOfStock)+" ")]):_vm._e(),(
          this.item.itemType == 'Package'
          )?_c('v-btn',{staticClass:"ma-1 white--text",attrs:{"disabled":!this.item.isInStock,"elevation":"0","block":"","color":"black"},on:{"click":_vm.openItemDetail}},[_vm._v(" "+_vm._s(_vm.item.isInStock ? _vm.localizedText[_vm.language].viewDetails : _vm.localizedText[_vm.language].outOfStock)+" ")]):_vm._e(),(this.item.itemType == 'IncensingPerfumingService')?_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":""},on:{"click":_vm.bookNow}},[_c('v-icon',[_vm._v("mdi-calendar")])],1):_vm._e()],1)])],1),_c('v-dialog',{attrs:{"width":"310"},model:{value:(_vm.giftDialog),callback:function ($$v) {_vm.giftDialog=$$v},expression:"giftDialog"}},[(
        _vm.item &&
        _vm.item.hasPromotion &&
        _vm.item.activePromotion.promotionType == 'FreeGiftFromList'
      )?_c('v-card',{attrs:{"loading":_vm.isLoadingFreeGifts}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.localizedText[_vm.language].freeGift)+" ")]),_c('v-divider'),_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedGift),callback:function ($$v) {_vm.selectedGift=$$v},expression:"selectedGift"}},_vm._l((_vm.freeGiftItems),function(currentItem){return _c('v-list-item',{key:currentItem.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var active = ref.active;
return [_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":currentItem.smallThumbnailUrl}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(currentItem.name)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1)]}}],null,true)})}),1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"outlined":"","depressed":"","color":"black","dark":"","block":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.localizedText[_vm.language].cancel)+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"dark":_vm.selectedGift != undefined,"color":"black","disabled":_vm.selectedGift == undefined,"block":""},on:{"click":_vm.done}},[_vm._v(" "+_vm._s(_vm.localizedText[_vm.language].done)+" ")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }