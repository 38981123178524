import { appBaseUrl } from "@/constants"

import { OrderClient } from "@/lib/api"


const initialState = () => ({
    api: new OrderClient(appBaseUrl),
    orders:[],
    orderPaymentResult:{},
    orderResult:{}

});

// State object
const state = {
    api: new OrderClient(appBaseUrl),
    orders:[],
    orderPaymentResult:{},
    orderResult:{}

}

// Getter functions
const getters = {
    getApi(state) {
        return state.api;
    },
    orders(state) {
        return state.orders;
    },
    orderPaymentResult(state){
        return state.orderPaymentResult
    },
    orderResult(state){
        return state.orderResult;
    }
    

}

// Actions 
const actions = {

    ///api/Booking/CustomerBookings
    async loadOrders({commit, state}, language){

        const response = await state.api.customerOrders(language);

        commit("loadOrdersCompleted", response);
    } ,


    async placeOrder({commit, state}, {paymentMethodId, customerNotes, language, additionalPaymentMethodId}){

        let model = {
            paymentMethodId,
            customerNotes,
            language,
            additionalPaymentMethodId,
            orderType: 3
        }
        const response = await state.api.placeOrder(model);

        commit("placeOrderCompleted", response);

    },

    async loadOrderResult({commit, state}, {orderNumber, refrenceId, language}){

 
        const response = await state.api.paymentDetailByNumberAndRefrenceId(orderNumber,refrenceId,language);

        commit("loadOrderResultCompleted", response);
    }

  
    
    
}

// Mutations
const mutations = {

    loadOrdersCompleted(state, orders){
        state.orders = orders;
    },

    placeOrderCompleted(state, paymentResult){
        state.orderPaymentResult = paymentResult;
    },

    loadOrderResultCompleted(state, orderResult){

        state.orderResult = orderResult;
    },

    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        });
    },
    loadToken(state){
        let token = window.localStorage.getItem('token') || ""
        state.api.instance.defaults.headers.common = {'Authorization': `Bearer ${token}`};
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}