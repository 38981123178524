<template>
  <div>
    <v-menu v-model="openMenu" offset-y bottom>
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          class="searchTextfield"
          @focus="
            searchClosed = false;
            openMenu = true;
          "
          @blur="onBlur()"
          v-model="searchText"
          single-line
          hide-details
          solo
          flat
          desnse
          :class="{ closed: searchClosed }"
          :placeholder="localizedText[language].search"
          prepend-inner-icon="mdi-magnify"
          color="black"
        ></v-text-field>
      </template>
      <v-sheet max-height="75vh">
        <div v-if="searchResult.length > 0">
          <ItemCardComponent
            v-for="(item, index) in searchResult"
            :key="index"
            :item="item"
          ></ItemCardComponent>
        </div>
        <div class="pa-2" v-else>
          <p class="text-center">No result</p>
        </div>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
import ItemCardComponent from "@/components/ItemCardComponent";
import { mapGetters } from "vuex";
export default {
  name: "SearchComponent",

  components: {
    ItemCardComponent,
  },

  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
    }),

    ...mapGetters("Catalog", {
      catalog: "catalog",
    }),
  },

  //********************** */
  data: () => ({
    searchClosed: true,
    openMenu: false,
    searchText: "",
    searchResult: [],
    select: null,
    localizedText: {
      en: {
        search: "Search ..",
      },
      ar: {
        search: "البحث ..",
      },
    },
  }),

  watch: {
    searchText(val) {
      this.queryItems();
      if (!this.openMenu) {
        this.openMenu = true;
      }
    },
  },

  methods: {
    onBlur: function () {
      let self = this;
      setTimeout(() => {
        self.searchClosed = true;
           self.openMenu = false;
      }, 100);
    },
    queryItems: function () {
      if (this.searchText == null) {
        this.searchResult = [];
      } else {
        let text = this.searchText;
        if (text.length > 0) {
          this.searchResult = this.catalog.items.filter((i) =>
            i.searchWords.includes(text)
          );
        } else {
          this.searchResult = [];
        }
      }
    },
  },
};
</script>

<style scoped>
.searchTextfield {
  transition: max-width 0.3s;
}
.closed {
  max-width: 45px;
}
</style>