<template>
  <div>
    <v-card @click="goToItem()" class="ma-1">
      <div class="d-flex flex-no-wrap">
        <v-avatar class="ma-3" size="70" tile>
          <v-img :src="item.smallThumbnailUrl"></v-img>
        </v-avatar>

        <div>
          <div>{{ item.categoryName }}</div>
          <v-card-title>{{ item.name }}</v-card-title>
          <v-card-subtitle> {{item.unitPrice}} KD </v-card-subtitle>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ItemCardComponent",

  //********************** */
  props: {
    item: {
      type: Object,
    },
  },

  methods:{
      goToItem: function(){
          
          if(this.item.itemType == 'GiveawayService'){
             this.$router.push({ path: `/giveawayitem/${this.item.id}` });
          }else if(this.item.itemType == 'IncensingPerfumingService'){
            console.log("IncensingPerfumingService")
          }else {
              this.$router.push({ path: `/item/${this.item.id}` });
          }

      }
  }
};
</script>

<style>
</style>