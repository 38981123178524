import { appBaseUrl } from "@/constants";

import { CartClient } from "@/lib/api";

const initialState = () => ({
  api: new CartClient(appBaseUrl),
  cart: { lineItems: [], showDeliverySpeedOption: true },
  wishList: {},
  uploadedFileInfo: { fileName: "" },
  //lineItems:[],
});

// State object
const state = {
  api: new CartClient(appBaseUrl),
  cart: { lineItems: [], showDeliverySpeedOption: true },
  wishList: {},
  uploadedFileInfo: { fileName: "" },
  //lineItems:[],
};

// Getter functions
const getters = {
  getApi(state) {
    return state.api;
  },
  cart(state) {
    return state.cart;
  },
  wishList(state) {
    return state.wishList;
  },
  uploadedFileInfo(state) {
    return state.uploadedFileInfo;
  },
};

// Actions
const actions = {
  async loadCart({ commit, state }, language) {
    const response = await state.api.cart(language);

    commit("loadCartCompleted", response);
  },

  async addItem(
    { state, dispatch },
    {
      quantity,
      itemId,
      language,
      giftItemId,
      withPlate,
      plateText,
      withCard,
      cardText,
      isGift,
    }
  ) {
    let model = {
      cartId: state.cart.id,
      quantity: quantity,
      itemId: itemId,
      language: language,
      giftItemId: giftItemId,
      withPlate: withPlate,
      plateText: plateText,
      withCard: withCard,
      cardText: cardText,
      isGift: isGift,
    };

    await state.api.addItem(model);

    await dispatch("loadCart", language);
  },

  async addItemWithGift(
    { state, dispatch },
    {
      quantity,
      itemId,
      language,
      giftItemId,
      withPlate,
      plateText,
      withCard,
      cardText,
      isGift,
    }
  ) {
    let model = {
      cartId: state.cart.id,
      quantity: quantity,
      itemId: itemId,
      language: language,
      giftItemId: giftItemId,
      withPlate: withPlate,
      plateText: plateText,
      withCard: withCard,
      cardText: cardText,
      isGift: isGift,
    };

    await state.api.addItemWithGift(model);

    await dispatch("loadCart", language);
  },

  async removeItem({ state, dispatch }, { lineItemId, language }) {
    let model = {
      cartId: state.cart.id,
      lineItemId: lineItemId,
      language: language,
    };

    await state.api.removeItem(model);

    await dispatch("loadCart", language);
  },

  async addMultipleItems({ state, dispatch }, { itemIds, language }) {
    let model = { cartId: state.cart.id, itemIds: itemIds, language: language };

    await state.api.addMultipleItems(model);

    await dispatch("loadCart", language);
  },

  async addItemWithPackage(
    { state, dispatch },{
    quantity,
    itemId,
    language,
    giftItemId,
    withPlate,
    plateText,
    withCard,
    cardText,
    isGift,
    packageItems,
     printText}
  ) {
    let model = {
      cartId: state.cart.id,
      quantity: quantity,
      itemId: itemId,
      language: language,
      giftItemId: giftItemId,
      withPlate: withPlate,
      plateText: plateText,
      withCard: withCard,
      cardText: cardText,
      isGift: isGift,
      packageItems: packageItems,
      printText
    };

    await state.api.addItemWithPackage(model);

    await dispatch("loadCart", language);
  },

  async addItemWithSpecialPromotion(
    { state, dispatch },
    { quantity, itemId, language }
  ) {
    let model = {
      cartId: state.cart.id,
      quantity: quantity,
      itemId: itemId,
      language: language,
    };

    await state.api.addItemWithSpecialPromotion(model);

    await dispatch("loadCart", language);
  },

  async removeItemWithSpecialPromotion(
    { state, dispatch },
    { lineItemId, language }
  ) {
    let model = {
      cartId: state.cart.id,
      lineItemId: lineItemId,
      language: language,
    };

    await state.api.removeItemWithSpecialPromotion(model);

    await dispatch("loadCart", language);
  },

  async updateQuantity(
    { state, dispatch },
    { lineItemId, quantity, language }
  ) {
    let model = {
      cartId: state.cart.id,
      lineItemId: lineItemId,
      quantity: quantity,
      language: language,
    };

    await state.api.updateQuantity(model);

    await dispatch("loadCart", language);
  },

  async updateQuantityOfItemWithSpecialPromotion(
    { state, dispatch },
    { lineItemId, quantity, language }
  ) {
    let model = {
      cartId: state.cart.id,
      lineItemId: lineItemId,
      quantity: quantity,
      language: language,
    };

    await state.api.updateQuantityOfItemWithSpecialPromotion(model);

    await dispatch("loadCart", language);
  },

  async assignShippingAddress(
    { state, dispatch },
    {
      addressType,
      firstName,
      middleName,
      lastName,
      email,
      countryCode,
      country,
      town,
      area,
      postalCode,
      phone,
      secondPhoneNumber,
      government,
      block,
      avenue,
      street,
      house,
      flat,
      floor,
      building,
      office,
      line1,
      line2,
      deliveryInstructions,
      language,
    }
  ) {
    let model = {
      cartId: state.cart.id,
      addressType,
      firstName,
      middleName,
      lastName,
      email,
      countryCode,
      country,
      town,
      area,
      postalCode,
      phone,
      secondPhoneNumber,
      government,
      block,
      avenue,
      street,
      house,
      flat,
      floor,
      building,
      office,
      line1,
      line2,
      deliveryInstructions,
      language,
    };

    await state.api.assignShippingAddress(model);

    await dispatch("loadCart", language);
  },

  async loadWishList({ commit, state }) {
    const response = await state.api.wishList();

    commit("loadWishListCompleted", response);
  },

  async addItemToWishList({ state, dispatch }, { itemId, language }) {
    let model = {
      wishListId: state.wishList.id,
      itemId: itemId,
      language: language,
    };

    await state.api.addItemToWishList(model);

    await dispatch("loadWishList");
  },

  async removeItemFromWishList(
    { state, dispatch },
    { wishListItemId, language }
  ) {
    let model = {
      wishListId: state.wishList.id,
      wishListItemId: wishListItemId,
      language: language,
    };

    await state.api.removeItemFromWishList(model);

    await dispatch("loadWishList");
  },

  async selectDeliverySpeed(
    { state, dispatch },
    { deliverSpeedOptionId, deliveryDate, deliveryTimeRange, language }
  ) {
    let model = {
      cartId: state.cart.id,
      language: language,
      deliverSpeedOptionId: deliverSpeedOptionId,
      deliveryDate: deliveryDate,
      deliveryTimeRange: deliveryTimeRange,
    };

    await state.api.selectDeliverySpeed(model);

    await dispatch("loadCart", language);
  },

  async addGiveawayItem(
    { state, dispatch },
    { itemId, deliveryDate, options, deliveryDay, quantity, language }
  ) {
    let model = {
      cartId: state.cart.id,
      quantity: quantity,
      itemId: itemId,
      language: language,
      options: options,
      deliveryDate: deliveryDate,
      deliveryDay: deliveryDay,
    };

    await state.api.addGiveawayItem(model);

    await dispatch("loadCart", language);
  },

  async addItemToReminderList({ commit, state }, { itemId, language }) {
    let model = {
      itemId: itemId,
      language: language,
    };

    await state.api.addItemToReminderList(model);

    commit("addItemToReminderListCompleted");
  },

  async updateItem(
    { state, dispatch },
    {
      quantity,
      lineItemId,
      language,
      giftItemId,
      withPlate,
      plateText,
      withCard,
      cardText,
      isGift,
    }
  ) {
    let model = {
      cartId: state.cart.id,
      quantity,
      lineItemId,
      language,
      giftItemId,
      withPlate,
      plateText,
      withCard,
      cardText,
      isGift,
    };

    await state.api.updateItem(model);

    await dispatch("loadCart", language);
  },

  async upload({ state, commit }, logoFile) {
    const response = await state.api.upload(logoFile);
    commit("uploadCompleted", response);
  },

  async validateCartStock({ state, commit }) {
    await state.api.validateCartStock();
    commit("validateCartStockCompleted");
  },
};

// Mutations
const mutations = {
  loadCartCompleted(state, cart) {
    state.cart = cart;
    //state.lineItems = cart.lineItems;
  },

  loadWishListCompleted(state, wishList) {
    state.wishList = wishList;
  },

  addItemToReminderListCompleted(state) {},
  uploadCompleted(state, uploadedFileInfo) {
    state.uploadedFileInfo = uploadedFileInfo;
  },

  validateCartStockCompleted() {},

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  loadToken(state) {
    let token = window.localStorage.getItem("token") || "";
    state.api.instance.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
