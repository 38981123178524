<template>
  <div>
    <CartLineItemComponent
      v-for="item in cart.lineItems"
      :key="item.id"
      :lineItem="item"
    ></CartLineItemComponent>
  </div>
</template>

<script>
import CartLineItemComponent from "@/components/CartLineItemComponent";
import { mapGetters } from "vuex";
export default {
  name: "CartComponent",
  components: { CartLineItemComponent },
  //********************** */
  data: () => ({}),

  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
    }),

    ...mapGetters("Cart", {
      cart: "cart",
      lineItems: "lineItems"
    }),
  },

  methods: {
    loadCart: async function () {
      await this.$store.dispatch("Cart/loadCart", this.language);
    },
  },

  async mounted () {
    //await this.loadCart();
  },
};
</script>

<style>
</style>