<template>
  <div class="d-flex align-center">
    <v-btn
      :loading="isDeleting"
      dense
      class="flex-grow-0"
      icon
      @click="removeItem"
      ><v-icon small>mdi-close</v-icon></v-btn
    >
    <v-card class="ma-1 flex-grow-1">
      <div class="d-flex justify-start">
        <v-avatar class="ma-1" size="60" tile>
          <v-img contain :src="lineItem.thumbnailUrl"> </v-img>
        </v-avatar>

        <div class="d-flex align-start flex-column py-1 flex-grow-1">
          <div class="caption">{{ lineItem.name }}</div>

          <div class="caption">
            <span style="font-size:8px">{{ displayPromotionText() }}</span>
          </div>
          <div v-if="lineItem.withPlate" class="caption">
            <span
              >{{ localizedText[language].plate }}
              {{ lineItem.plateText }}</span
            >
          </div>
          <div v-if="lineItem.withCard" class="caption">
            <span
              >{{ localizedText[language].card }} {{ lineItem.cardText }}</span
            >
          </div>
          <div v-if="lineItem.itemType == 'Package'" class="caption">
            <span >{{ lineItem.selectedPackageItemDisplayText }}</span>
          </div>
          <div class="caption mt-auto">
            <span class="actualPriceClass">{{ displayActualPrice() }}</span>
            <span v-if="hasDiscountPromotion()" class="original-price">{{
              displayOriginalPrice()
            }}</span>
          </div>
        </div>

        <div v-if="lineItem.itemType != 'Package'" class="d-flex justify-space-around flex-column pa-1 align-center">
          <v-btn :disabled="isDecrementing" :loading="isIncrementing" @click="increment()" icon small ><v-icon small>mdi-plus</v-icon></v-btn>

          <div class="caption">{{lineItem.quantity}}</div>
          <v-btn :loading="isDecrementing" @click="decrement()" :disabled="isIncrementing || shouldDisableDecrement()" icon small ><v-icon small>mdi-minus</v-icon></v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CartLineItemComponent",

  //********************** */
  props: {
    lineItem: {
      type: Object,
    },
  },
  //********************** */
  data: () => ({
    isDeleting: false,
    isIncrementing: false,
    isDecrementing:false,
    localizedText: {
      en: {
        plate: "Plate :",
        card: "Card :",
        freeGift: "Free gift:"
      },
      ar: {
        plate: "البليت :",
        card: "الكرت :",
        freeGift: "هدية مجانية:"
      },
    },
  }),
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
    }),
  },

  methods: {

    shouldDisableDecrement: function(){
      if(this.lineItem.itemType == 'GiveawayService'){
        return this.lineItem.quantity <= 20
      }else {
        return this.lineItem.quantity <= 1
      }
    },
    increment: async function(){

      this.isIncrementing = true;
      let model =   { lineItemId : this.lineItem.id, quantity: this.lineItem.quantity + 1, language : this.language};

        if (this.lineItem.itemType == "MultiPrice") {
          await this.$store.dispatch("Cart/updateQuantityOfItemWithSpecialPromotion", model);
        }else {
          await this.$store.dispatch("Cart/updateQuantity", model);
        }

         this.isIncrementing = false;

    },

     decrement: async function(){

       this.isDecrementing = true;


         let model =   { lineItemId : this.lineItem.id, quantity: this.lineItem.quantity - 1, language : this.language};

        if (this.lineItem.itemType == "MultiPrice") {
          await this.$store.dispatch("Cart/updateQuantityOfItemWithSpecialPromotion", model);
        }else {
          await this.$store.dispatch("Cart/updateQuantity", model);
        }

        this.isDecrementing = false;

    },

    removeItem: async function () {
      this.isDeleting = true;
      let model = {
        lineItemId: this.lineItem.id,
        language: this.language,
      };

      if(this.lineItem.itemType == 'MultiPrice'){
        await this.$store.dispatch("Cart/removeItemWithSpecialPromotion", model);
      }else {
        await this.$store.dispatch("Cart/removeItem", model);
      }
      
      this.isDeleting = true;
    },

    displayActualPrice: function () {
      let totalForCountries = this.lineItem.totalForCountries;

      let totalPriceForCountry = Object.prototype.hasOwnProperty.call(
        totalForCountries,
        this.currencyCode
      )
        ? totalForCountries[this.currencyCode]
        : 0;

      if (totalPriceForCountry > 0) {
        return `${totalPriceForCountry} ${this.currency.symbol}`;
      }

      let totalPrice = this.currency.value * this.lineItem.total;

      return `${totalPrice} ${this.currency.symbol}`;
    },

    displayOriginalPrice: function () {
      let unitPriceForCountries = this.lineItem.unitPriceForCountries;

      let unitPriceForCountry = Object.prototype.hasOwnProperty.call(
        unitPriceForCountries,
        this.currencyCode
      )
        ? unitPriceForCountries[this.currencyCode]
        : 0;

      if (unitPriceForCountry > 0) {
        let totalForCountries = this.lineItem.totalForCountries;

        let totalForCountry = Object.prototype.hasOwnProperty.call(
          totalForCountries,
          this.currencyCode
        )
          ? totalForCountries[this.currencyCode]
          : this.lineItem.total;

        let quantity = this.lineItem.quantity;

        let price = unitPriceForCountry * quantity;

        if (this.lineItem.itemType == "MultiPrice") {
          if (price <= totalForCountry) {
            return "";
          }
        }

        return `${price} ${this.currency.symbol}`;
      }

      let price =
        this.lineItem.unitPrice * this.lineItem.quantity * this.currency.value;

      if (this.lineItem.itemType == "MultiPrice") {
        let t = this.lineItem.unitPrice * this.lineItem.quantity;
        if (this.lineItem.total >= t) {
          return "";
        }
      }

      return `${price} ${this.currency.symbol}`;
    },

    hasMultiPriceDiscount: function () {
      if (this.lineItem.itemType == "MultiPrice") {
        let t = this.lineItem.unitPrice * this.lineItem.quantity;
        if (this.lineItem.total >= t) {
          return true;
        }
      }

      return false;
    },

    displayMultiplePrices: function () {
      if (this.lineItem.itemType == "MultiPrice") {
        if (this.lineItem.individualItemPrices) {
          return this.lineItem.individualItemPrices
            .map((i) => `${i.price}`)
            .join(" + ");
        }
      }

      return "";
    },

    hasDiscountPromotion: function () {
      if (this.lineItem.promotion) {
        return this.lineItem.promotion.promotionType == "Discount";
      }

      return false;
    },

    displayPromotionText: function () {
      if (this.lineItem.promotion) {
        if (this.lineItem.itemType == "MultiPrice") {
          return this.displayMultiplePrices();
        } else if (this.lineItem.itemType == "GiveawayService") {
          return this.lineItem.giveawayItemSummary;
        }else if(this.lineItem.promotion.promotionType == "FreeGiftFromList"){
          return this.language == "en" ? `Free gift: ${this.lineItem.promotion.giftItemEnglishName}` :`هدية مجانية: ${this.lineItem.promotion.giftItemArabicName}`;
        }
      }

      return "";
    },
  },
};
</script>

<style>
original-price {
  text-decoration: line-through;
}
</style>