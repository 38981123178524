<template>
  <div>
    <v-list-item to="/">
      <v-list-item-icon>
        <v-icon color="black" small>mdi-home</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          localizedText[language].home
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item to="/MyOrders">
      <v-list-item-icon>
        <v-icon color="black" small>mdi-cart</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          localizedText[language].myOrders
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- <v-list-item>
      <v-list-item-icon>
        <v-icon color="black" small>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          localizedText[language].myBookings
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->

    <v-list-item to="/Wallet" v-if="isRegistered">
      <v-list-item-icon>
        <v-icon color="black" small>mdi-wallet</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          localizedText[language].myWallet
        }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text
          >{{ wallet.balance }}
          {{ localizedText[language].kd }}</v-list-item-action-text
        >
      </v-list-item-action>
    </v-list-item>

    <!--   <v-list-item>
      <v-list-item-icon>
        <v-icon color="black" small>mdi-gift-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          localizedText[language].buyVoucher
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->

    <v-list-item to="/MyAddressBook">
      <v-list-item-icon>
        <v-icon color="black" small>mdi-notebook-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          localizedText[language].addressBook
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item @click="showLanguageDialog = true">
      <v-list-item-icon>
        <v-icon color="black" small>mdi-web</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          localizedText[language].language
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item @click="showContriesDialog = true">
      <v-list-item-icon>
        <v-avatar size="18">
          <v-img cover :src="flag"></v-img>
        </v-avatar>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          localizedText[language].country
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item @click="showCurrenciesDialog = true">
      <v-list-item-icon>
        <v-icon color="black" small>mdi-web</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          >{{ localizedText[language].currency }}
          {{ currencyCode }}</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>


     <v-list-item @click="showContactUsDialog = true">
      <v-list-item-icon>
        <v-icon color="black" small>mdi-message-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          localizedText[language].getHelp
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-dialog v-model="showLanguageDialog" max-width="300">
      <LanguagePickerComponent v-on:language-selected="langaugeChanged" />
    </v-dialog>

    <v-dialog v-model="showContriesDialog" max-width="300">
      <CountryPickerComponent v-on:country-selected="countryChanged" />
    </v-dialog>

    <v-dialog v-model="showCurrenciesDialog" max-width="300">
      <CurrencyPickerComponent v-on:currency-selected="currencyChanged" />
    </v-dialog>

    <v-dialog v-model="showContactUsDialog" max-width="300">
      <ContactUsComponent  />
    </v-dialog>

    <v-dialog max-width="200" v-model="showUpdatingDialog">
      <v-card class="d-flex justify-center pa-2">
        <v-progress-circular
          :size="32"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-card>

      
    </v-dialog>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LanguagePickerComponent from "@/components/LanguagePickerComponent";
import CountryPickerComponent from "@/components/CountryPickerComponent";
import CurrencyPickerComponent from "@/components/CurrencyPickerComponent";
import ContactUsComponent from "@/components/ContactUsComponent";

export default {
  name: "NavDrawerComponent",

  components: {
    LanguagePickerComponent,
    CountryPickerComponent,
    CurrencyPickerComponent,
    ContactUsComponent
  },
  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      currency: "currency",
      currencyCode: "currencyCode",
    }),
    ...mapGetters("Cart", {
      cart: "cart",
    }),
    ...mapGetters("Wallet", {
      wallet: "wallet",
    }),

    flag: function () {
      return require(`@/assets/flags/${this.countryCode.toLowerCase()}.png`);
    },
  },
  data: () => ({
    showLanguageDialog: false,
    showContriesDialog: false,
    showCurrenciesDialog: false,
    showUpdatingDialog: false,
    showContactUsDialog: false,
    localizedText: {
      en: {
        home: "Home",
        myOrders: "My Orders",
        myBookings: "My Bookings",
        myWallet: "My Wallet",
        buyVoucher: "Buy Voucher",
        addressBook: "Address Book",
        language: "Language",
        country: "Country",
        currency: "Currency",
        kd: "KD",
        getHelp: "Get help"
      },
      ar: {
        home: "الرئيسية",
        myOrders: "طلباتي",
        myBookings: "حجوزاتي",
        myWallet: "محفظتي",
        buyVoucher: "شراء كوبون هدية",
        addressBook: "سجل العناوين",
        language: "اللغة",
        country: "الدولة",
        currency: "العملة",
        kd: "د.ك",
        getHelp: "احصل على المساعدة"
      },
    },
  }),
  methods: {
    currencyChanged: async function () {
      this.showCurrenciesDialog = false;
      this.showUpdatingDialog = true;
      let homePageModel = {
        countryCode: this.countryCode,
        currencyCode: this.currencyCode,
        language: this.language,
      };
      await this.loadCart();
      await this.loadCatalog();
      await this.$store.dispatch("Account/loadCurrency");
      await this.$store.dispatch("Account/loadCustomerInfo");
      await this.$store.dispatch(
        "Catalog/loadMainPageCategories",
        this.language
      );
      await this.$store.dispatch(
        "Catalog/loadHomePageCategories",
        homePageModel
      );
      this.showUpdatingDialog = false;
      // this.$router.push('home')
      //this.$router.push({ path: `/` })
      global.vm.$forceUpdate();
    },

    langaugeChanged: async function () {
      this.showLanguageDialog = false;

      if (this.language === "en") {
        this.$vuetify.rtl = false;
      } else {
        this.$vuetify.rtl = true;
      }
      //console.log(this.currency)
      this.showUpdatingDialog = true;
      await this.loadCart();
      await this.loadCatalog();
        await this.$store.dispatch("Account/loadCurrency");
      //await this.$store.dispatch("Account/loadCustomerInfo"); 
       let homePageModel = {
        countryCode: this.countryCode,
        currencyCode: this.currencyCode,
        language: this.language,
      };
      await this.$store.dispatch(
        "Catalog/loadMainPageCategories",
        this.language
      );
      await this.$store.dispatch(
        "Catalog/loadHomePageCategories",
        homePageModel
      );
      await this.$store.dispatch(
        "Catalog/loadPaymentMethods",
        this.countryCode
      );
      this.showUpdatingDialog = false;

      global.vm.$forceUpdate();
    },

    countryChanged: async function () {
      this.showContriesDialog = false;

      await this.loadCart();
      await this.loadCatalog();
      await this.$store.dispatch("Account/loadCustomerInfo");
      await this.$store.dispatch(
        "Catalog/loadMainPageCategories",
        this.language
      );
      await this.$store.dispatch(
        "Catalog/loadHomePageCategories",
        this.language
      );
      await this.$store.dispatch(
        "Catalog/loadPaymentMethods",
        this.countryCode
      );

      //this.$router.push({ path: `/` })
      global.vm.$forceUpdate();
    },

    loadCart: async function () {
      await this.$store.dispatch("Cart/loadCart", this.language);
    },
    loadCatalog: async function () {
      await this.$store.dispatch("Catalog/freshLoadCatalog", this.language);
    },
  },
};
</script>

<style scoped>
.active-class {
  font-weight: bold;
  background-color: whitesmoke;
}
</style>