<template>
  <div>
    <v-card flat tile>
      <v-card-text>
        <v-tabs fixed-tabs color="black">
          <v-tab>{{ localizedText[language].userInfo }}</v-tab>
          <v-tab>{{ localizedText[language].changingPassword }}</v-tab>
          <v-tab>{{ localizedText[language].logout }}</v-tab>
          <v-tab-item>
            <v-card class="mx-1 my-1">
              <v-card-text>
                <div>{{ localizedText[language].userInfo }}</div>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field
                        v-model="firstName"
                        prepend-icon="mdi-account"
                        :label="localizedText[language].firstName"
                      ></v-text-field>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field
                        v-model="middleName"
                        prepend-icon="mdi-account"
                        :label="localizedText[language].middleName"
                      ></v-text-field>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field
                        v-model="lastName"
                        prepend-icon="mdi-account"
                        :label="localizedText[language].lastName"
                      ></v-text-field>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-text-field
                      v-model="phone"
                      prepend-icon="mdi-phone"
                      :label="localizedText[language].phone"
                    ></v-text-field>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-text-field
                      v-model="email"
                      prepend-icon="mdi-email"
                      :label="localizedText[language].email"
                    ></v-text-field>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-content>
                    <v-dialog
                      ref="dialog"
                      v-model="showDatePickerDialog"
                      :return-value.sync="dateOfBirth"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateOfBirth"
                          :label="localizedText[language].dateOfBirth"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        color="black lighten-1"
                        header-color="black"
                        :show-current="false"
                        v-model="dateOfBirth"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="showDatePickerDialog = false"
                        >
                          {{ localizedText[language].cancel }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(dateOfBirth)"
                        >
                          {{ localizedText[language].ok }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
              <v-card-actions justify-center>
                <v-btn
                  :loading="isUpdatingInfo"
                  @click="saveChanges"
                  color="black"
                  dark
                  block
                  >{{ localizedText[language].save }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="mx-1 my-1">
              <v-card-text>
                <div>{{ localizedText[language].changingPassword }}</div>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field
                        v-model="currentPassword"
                        :append-icon="
                          showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="showCurrentPassword ? 'text' : 'password'"
                        prepend-icon="mdi-account-key"
                        :label="localizedText[language].currentPassword"
                        @click:append="
                          showCurrentPassword = !showCurrentPassword
                        "
                      ></v-text-field>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-text-field
                      v-model="newPassword"
                      :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showNewPassword ? 'text' : 'password'"
                      prepend-icon="mdi-account-key"
                      :label="localizedText[language].newPassword"
                      @click:append="showNewPassword = !showNewPassword"
                    ></v-text-field>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions justify-center>
                  <v-btn
                    :loading="isChangingPassword"
                    @click="changePassword"
                    color="black"
                    dark
                    block
                    >{{ localizedText[language].changePassword }}</v-btn
                  >
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
              <v-card>
                  <v-card-title>{{localizedText[language].logout }}</v-card-title>
                  <v-card-text>
                      <p>{{localizedText[language].logoutMessage}} </p>
                  </v-card-text>
                   <v-card-actions justify-center>
                  <v-btn
                    :loading="isLoggingOut"
                    @click="logOut"
                    color="black"
                    dark
                    block
                    >{{ localizedText[language].logout }}</v-btn
                  >
                </v-card-actions>
              </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProfileComponent",
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      customerInfo: "customerInfo",
    }),

    ...mapGetters("Catalog", {
      countries: "countries",
    }),
  },
  data: () => ({
      isLoggingOut: false,
    isUpdatingInfo: false,
    showCurrentPassword: false,
    showNewPassword: false,
    isChangingPassword: false,
    showDatePickerDialog: false,
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    phone: "",
    dateOfBirth: null,
    dateOfBirthMenu: false,
    localizedText: {
      en: {
        userInfo: "User Info",
        firstName: "First name",
        middleName: "Middle name",
        lastName: "Last name",
        phone: "Phone number",
        email: "Email",
        currentPassword: "Current password",
        newPassword: "New password",
        changePassword: "Change password",
        changingPassword: "Changing password",
        save: "Save Changes",
        dateOfBirth: "Date of birth",
        ok: "Ok",
        cancel: "Cancel",
        logout: "Log out",
        logoutMessage: "Are you sure you want to log out of this website?"
      },
      ar: {
        userInfo: "معلومات المستحدم",
        firstName: "الاسم الأول",
        middleName: "الاسم الثاني",
        lastName: "اللقب",
        phone: "رقم الهاتف",
        email: "الايميل",
        currentPassword: "كلمة المرور الحالية",
        newPassword: "كلمة المرور الجديدة",
        changePassword: "تغيير كلمة المرور",
        changingPassword: "تغيير كلمة المرور",
        save: "حفظ التعديلات",
        dateOfBirth: "تاريخ الميلاد",
        ok: "تم",
        cancel: "الغاء",
        logout: "تسجيل الخروج",
        logoutMessage: "هل أنت متأكد من تسجيل الخروج من الموقع؟"
      },
    },
  }),
  methods: {
    saveChanges: async function () {
      var model = {
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        phone: this.phone,
        email: this.email,
        dateOfBirth: this.dateOfBirth,
        language: this.language,
        countryCode: this.countryCode
      };
      this.isUpdatingInfo = true;
      await this.$store.dispatch("Account/updateCustomerInfo", model);
      this.isUpdatingInfo = false;
    },

    changePassword: async function () {
      var model = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
      };
      this.isChangingPassword = true;
      await this.$store.dispatch("Account/changePassword", model);
      this.isChangingPassword = false;
    },
    loadCustomerInfo: async function () {
      await this.$store.dispatch("Account/loadCustomerInfo");
      this.firstName = this.customerInfo.firstName;
      this.middleName = this.customerInfo.middleName;
      this.lastName = this.customerInfo.lastName;
      this.email = this.customerInfo.email;
      this.phone = this.customerInfo.phone;
      this.dateOfBirth = this.customerInfo.dateOfBirth;
    },

   refreshData: async function () {
       this.isLoggingOut = true;
      this.$store.dispatch("loadToken");
      await this.$store.dispatch("Account/signUpAnonymous");
      this.$store.dispatch("loadToken");
      await this.$store.dispatch("Cart/loadCart", this.language);
      await this.$store.dispatch("Catalog/loadCatalog", this.language);
      await this.$store.dispatch("Account/loadCustomerInfo");
      this.isLoggingOut = false;
    },

    logOut: async function(){
        this.$store.dispatch("Account/logOut");
        await this.refreshData();

    }
  },

  async mounted() {
    await this.loadCustomerInfo();
  },
  watch: {
    showDatePickerDialog(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
};
</script>

<style>
</style>