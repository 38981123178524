<template>
  <div>
    <v-card flat tile>
      <v-img
        class="mx-auto my-1"
        max-width="150"
        height="150"
        src="@/assets/saray.png"
      ></v-img>
      <v-card-text>
        <v-tabs fixed-tabs color="black">
          <v-tab>{{ localizedText[language].signIn }}</v-tab>
          <v-tab>{{ localizedText[language].signUp }}</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-text-field
                  autocomplete="username"
                  v-model="signInModel.username"
                  prepend-icon="mdi-account-circle"
                  :label="localizedText[language].username"
                ></v-text-field>

                <v-text-field
                  autocomplete="current-password"
                  @click:append="
                    signInModel.showPassword = !signInModel.showPassword
                  "
                  :type="signInModel.showPassword ? 'text' : 'password'"
                  :append-icon="
                    signInModel.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  v-model="signInModel.password"
                  prepend-icon="mdi-lock"
                  :label="localizedText[language].password"
                ></v-text-field>
              </v-card-text>

              <v-card-actions justify-center>
                <v-btn :loading="isSigningIn" block dark @click="signIn()">
                  {{ localizedText[language].signIn }}
                </v-btn>
              </v-card-actions>

              <v-btn
                class="ma-2"
                color="grey darken-1"
                text
                block
                @click="forgotPassword()"
              >
                {{ localizedText[language].forgotPassword }}
              </v-btn>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-text-field
                  v-model="signUpModel.firstName"
                  :label="localizedText[language].firstName"
                ></v-text-field>

                <v-text-field
                  v-model="signUpModel.middleName"
                  :label="localizedText[language].middleName"
                ></v-text-field>

                <v-text-field
                  v-model="signUpModel.lastName"
                  :label="localizedText[language].lastName"
                ></v-text-field>

                <v-text-field
                  v-model="signUpModel.phone"
                  :label="localizedText[language].phone"
                  dir="ltr"
                  prepend-icon="mdi-phone"
                  :prefix="
                    language == 'en' ? country.dialCode.substring(1) : ''
                  "
                  :suffix="
                    language == 'ar' ? country.dialCode.substring(1) : ''
                  "
                >
                </v-text-field>

                <v-text-field
                  v-model="signUpModel.username"
                  prepend-icon="mdi-account-circle"
                  :label="localizedText[language].email"
                ></v-text-field>

                <v-text-field
                  @click:append="
                    signUpModel.showPassword = !signUpModel.showPassword
                  "
                  :type="signUpModel.showPassword ? 'text' : 'password'"
                  :append-icon="
                    signUpModel.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  v-model="signUpModel.password"
                  prepend-icon="mdi-lock"
                  :label="localizedText[language].password"
                ></v-text-field>
              </v-card-text>

              <v-card-actions justify-center>
                <v-btn :loading="isSigningUp" block dark @click="signUp()">
                  {{ localizedText[language].signUp }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LogInComponent",
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
      country: "country"
    }),

    ...mapGetters("Catalog", {
      countries: "countries",
    }),
  },
  data: () => ({
    
    localizedText: {
      en: {
        username: "Username",
        password: "Password",
        signIn: "Sign In",
        signUp: "Sign Up",
        firstName: "First name",
        lastName: "Last name",
        middleName: "Middle name",
        email: "Email",
        phone: "Phone",
        forgotPassword: "Forgot password?",
      },
      ar: {
        username: "اسم المستخدم",
        password: "كلمة المرور",
        signIn: "تسجيل الدخول",
        signUp: "انشاء حساب",
        firstName: "الاسم الأول",
        lastName: "اللقب(الاسم الأخير)",
        middleName: "الاسم االثاني",
        email: "الايميل",
        phone: "التلفون",
        forgotPassword: "نسيت كلمة المرور؟",
      },
    },
    isSigningIn: false,
    isSigningUp: false,
    signInModel: { username: "", password: "", showPassword: false },
    signUpModel: {
      username: "",
      password: "",
      showPassword: false,
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
    },
  }),

  filters: {},

  methods: {
    signIn: async function () {
      let model = {
        userName: this.signInModel.username,
        password: this.signInModel.password,
      };
      this.isSigningIn = true;
      await this.$store.dispatch("Account/logIn", model);
      this.isSigningIn = false;
      await this.refreshData();
    },

    signUp: async function () {
      let model = {
        firstName: this.signUpModel.firstName,
        middleName: this.signUpModel.middleName,
        lastName: this.signUpModel.lastName,
        phone: this.signUpModel.phone,
        email: this.signUpModel.username,
        countryCode: this.countryCode,
        password: this.signUpModel.password,
      };

      this.isSigningUp = true;
      await this.$store.dispatch("Account/signUp", model);
      this.isSigningUp = false;
      await this.refreshData();
    },

    forgotPassword: function () {},

    loadCountries: async function () {
      await this.$store.dispatch("Catalog/loadCountries");
    },

    refreshData: async function () {
      this.$store.dispatch("loadToken");
      await this.$store.dispatch("Cart/loadCart", this.language);
      await this.$store.dispatch("Catalog/loadCatalog", this.language);
      await this.$store.dispatch("Account/loadCustomerInfo");
      
    },
  },

  mounted: async function () {
    await this.loadCountries();
  },
};
</script>

<style>
</style>