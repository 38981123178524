<template>
  <div>
    <v-card max-width="1100" elevation="1" class="mx-auto">
      <v-responsive :aspect-ratio="1200 / 390">
        <v-carousel height="auto" cycle hide-delimiters :show-arrows="false">
          <v-carousel-item @click.native="homeBannerItemClicked(item)" v-for="(item, i) in homeBanner.bannarItems"
            :key="i" :src="item.imageUrl">
          </v-carousel-item>
        </v-carousel>
      </v-responsive>
    </v-card>

    <br>

    <MainPageCategoriesComponent></MainPageCategoriesComponent>
    <br />
    <v-card  elevation="0" v-for="category in homePageCategories" :key="category.id" class="mx-auto my-3 px-6"
      max-width="1100">
      <h3 class="my-2">{{ category.name }}</h3>
      <VueSlickCarousel v-bind="sliderSettings" v-if="category.items.length">
        <template #prevArrow>
          <v-btn style="z-index:2" icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <template #nextArrow>
          <v-btn style="z-index:2" icon>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
        <ItemComponent v-for="item in category.items" :key="item.id" :item="item" :maxWidth="200"
          :promotions="item.promotions"></ItemComponent>
      </VueSlickCarousel>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemComponent from "@/components/ItemComponent";
//import SmallItemComponent from "@/components/SmallItemComponent";

import MainPageCategoriesComponent from "@/components/MainPageCategoriesComponent";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "HomePageComponent",

  components: { ItemComponent, VueSlickCarousel, MainPageCategoriesComponent },

  //********************** */
  data: () => ({
    sliderSettings: {
      dots: false,
      arrows: true,
      infinite: false,
      centerMode: false,
      centerPadding: "20px",
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    },
  }),

  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      countryCode: "countryCode"
    }),

    ...mapGetters("Catalog", {
      homeBanner: "homeBanner",
      homePageCategories: "homePageCategories",
      catalog: "catalog"
    }),
  },
  //********************** */
  methods: {
    loadHomeBanner: async function () {
      let language = this.language;

      await this.$store.dispatch("Catalog/loadHomeBanner", language);
    },

    homeBannerItemClicked: function (bannerItem) {
      if (bannerItem.destinationType == 'Item') {
        let item = this.catalog.items.find(i => i.outlineId == bannerItem.destinationOutlineId);
        if (item) {
          this.$router.push({ path: `/item/${item.id}` });
        }

      } else {
        this.$router.push({ path: `/category/${bannerItem.destinationOutlineId}` });
      }
    },

    loadHomePageCategories: async function () {
      let language = this.language;
      let countryCode = this.countryCode;
      let currencyCode = this.currencyCode;

      await this.$store.dispatch("Catalog/loadHomePageCategories", { countryCode, currencyCode, language });
    },
  },

  //********************** */

  async mounted() {
    await this.loadHomeBanner();
    await this.loadHomePageCategories();
  },

  created() { },
};
</script>

<style>
</style>