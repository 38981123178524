<template>
  <div>
    <v-card>
      <v-card-title>
        {{ localizedText[language].chooseCountry }}
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selectedCountry"
          :items="items"
          outlined
          dense
          :label="localizedText[language].chooseCountry"
          item-text="name"
          item-value="code"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="isLoading" dark block @click="save">
          {{ localizedText[language].save }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CountryPickerComponent",
  data: () => ({
    isLoading: false,
    selectedCountry: "KW",
    items: [],
    localizedText: {
      en: {
        chooseCountry: "Choose country",
        save: "Save",
      },
      ar: {
        chooseCountry: "اختر الدولة",
        save: "حفظ",
      },
    },
  }),
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
      countryCode: "countryCode",
    }),
    ...mapGetters("Catalog", {
      countries: "countries",
    }),
  },

  methods: {
    save: async function () {
      this.isLoading = true;
      await this.$store.dispatch("Account/updateCountry", this.selectedCountry);
      let code = this.selectedCountry;
      let selectedCountry = this.countries.find(c => c.code == code);
      await this.$store.dispatch("Account/updateSelectedCountry", selectedCountry);
      this.isLoading = false;
      this.$emit("country-selected");
    },
  },

  async mounted() {
    await this.$store.dispatch("Catalog/loadCountries");

    this.selectedCountry = this.countryCode;
    let isEnglish = this.language == "en";
    this.items = this.countries.map((c) => {
      return { name: isEnglish ? c.name.english : c.name.arabic, code: c.code };
    });
  },
};
</script>

<style>
</style>