<template>
  <div>
    <div class="category-container">
      <div class="inner-container">
        <div class="category-title-container">
          <div style="font-size: 16px; font-weight: 500">
            <span>{{ localizedText[language].shopByCategory }}</span>
          </div>
        </div>

        <div class="d-flex flex-nowrap align-center">
          <v-btn @click="swipeLeft" icon
            ><v-icon v-if="language == 'en'">mdi-arrow-left</v-icon>
            <v-icon v-else>mdi-arrow-right</v-icon>
          </v-btn>

          <div id="content" ref="content" class="scrolling-div">
            <div
              v-for="category in mainPageCategories"
              :key="category.id"
              class="category-card"
              @click="openCategoryController(category.outlineId)"
            >
              <img :src="category.imageUrl" style="width: 100%" />
              <div class="category-title">
                {{ category.name }}
              </div>
            </div>
          </div>
          <v-btn @click="swipeRight" icon>
            <v-icon v-if="language == 'en'">mdi-arrow-right</v-icon>
            <v-icon v-else>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainPageCategoriesComponent",

  components: {},
  //********************** */
  data: () => ({
    sliderSettings: {
      dots: false,
      arrows: true,
      infinite: true,
      centerMode: false,
      centerPadding: "20px",
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    },

    localizedText: {
      en: {
        shopByCategory: "Shop by category",
      },
      ar: {
        shopByCategory: "تسوق حسب القسم",
      },
    },
  }),
  //********************** */
  computed: {
    ...mapGetters("Account", {
      language: "language",
    }),
    ...mapGetters("Catalog", {
      mainPageCategories: "mainPageCategories",
    }),
  },

  //********************** */
  methods: {
    loadMainPageCategories: async function () {
      let language = this.language;

      await this.$store.dispatch("Catalog/loadMainPageCategories", language);
    },

    openCategoryController: function (outlineId) {
      this.$router.push({ path: `/category/${outlineId}` });
    },

    scrollTo: function (element, scrollPixels, duration) {
      var scrollPos = element.scrollLeft;

      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();

        let scroll = function (timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        };
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft: function () {
      const content = this.$refs.content;
      this.scrollTo(content, -300, 300);
    },
    swipeRight: function () {
      console.log("swipeRight");
      const content = this.$refs.content;
      this.scrollTo(content, 300, 300);
    },
  },
  //********************** */

  async mounted() {
    await this.loadMainPageCategories();
  },
};
</script>

<style scoped>
.category-container {
  padding: 25px;
}

.category-title-container {
  display: flex;
  justify-content: space-between;
  padding: 6px;
}

.scrolling-div {
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
  margin: 0px;
}
.inner-container {
  margin: auto;
  max-width: 1100px;
  background-image: url("~@/assets/Pattern.svg");
  background-repeat: repeat;
}

.scrolling-card {
  display: inline-block;
}

.scrolling-div::-webkit-scrollbar {
  display: none;
}

.category-card {
  width: 80px;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  cursor: pointer;
}
.category-card img {
  /*border-radius: 50%;*/
  margin: auto;
  display: block;
}

.category-title {
  text-align: center;
  font-size: 1.2vh;
  /*word-break: break-all;*/
  white-space: normal;
  /*  background-color: orangered;*/
  /*margin-top:2px;*/
}
</style>