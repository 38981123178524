import { appBaseUrl } from "@/constants";

import { AccountClient } from "@/lib/api";

const initialState = () => ({
  api: new AccountClient(appBaseUrl),
  customerInfo: JSON.parse(window.localStorage.getItem("customerInfo")) || {},
  isRegistered:
    JSON.parse(window.localStorage.getItem("isRegistered")) || false,
  language: window.localStorage.getItem("language") || "en",
  countryCode: window.localStorage.getItem("countryCode") || "KW",
  currencyCode: window.localStorage.getItem("currencyCode") || "KWD",
  currency:  JSON.parse(window.localStorage.getItem("currency")) || {
    code: "KWD",
    symbol: "KD",
    value: 1,
  },
  token: window.localStorage.getItem("token") || "",
  country:  JSON.parse(window.localStorage.getItem("country")) || {
    name: {
      english: "Kuwait",
      arabic: "الكويت",
    },
    code: "KW",
    sortOrder: 0,
    showInMainList: true,
    dialCode: "+965",
    id: 216,
  },
});

// State object
const state = {
  api: new AccountClient(appBaseUrl),
  customerInfo: JSON.parse(window.localStorage.getItem("customerInfo")) || {},
  isRegistered:
    JSON.parse(window.localStorage.getItem("isRegistered")) || false,
  language: window.localStorage.getItem("language") || "ar",
  countryCode: window.localStorage.getItem("countryCode") || "KW",
  currencyCode: window.localStorage.getItem("currencyCode") || "KWD",
  currency: JSON.parse(window.localStorage.getItem("currency")) || {
    code: "KWD",
    symbol: "KD",
    value: 1,
  },
  token: window.localStorage.getItem("token") || "",
  country: JSON.parse(window.localStorage.getItem("country")) || {
    name: {
      english: "Kuwait",
      arabic: "الكويت",
    },
    code: "KW",
    sortOrder: 0,
    showInMainList: true,
    dialCode: "+965",
    id: 216,
  },
};

// Getter functions
const getters = {
  getApi(state) {
    return state.api;
  },
  customerInfo(state) {
    return state.customerInfo;
  },
  isRegistered(state) {
    return state.isRegistered;
  },
  language(state) {
    return state.language;
  },
  countryCode(state) {
    return state.countryCode;
  },
  currencyCode(state) {
    return state.currencyCode;
  },
  currency(state) {
    return state.currency;
  },
  token(state) {
    return state.token;
  },
  country(state) {
      return state.country;
  },
  isLoggedIn() {
    let token = window.localStorage.getItem("token") || "";
    return token !== "";
  },
};

// Actions
const actions = {
  async signUp(
    { commit, state },
    {
      firstName,
      middleName,
      lastName,
      phone,
      email,
      dateOfBirth,
      countryCode,
      password,
    }
  ) {
    let model = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      phone: phone,
      email: email,
      password: password,
      dateOfBirth: dateOfBirth,
      countryCode: countryCode,
      language: state.language,
    };

    //response = Bearer Token
    const response = await state.api.signUp(model);

    commit("signUpCompleted", response);
  },

  async logIn({ commit, state }, { userName, password }) {
    let model = {
      userName: userName,
      password: password,
      language: state.language,
    };

    //response = Bearer Token
    const response = await state.api.logIn(model);

    commit("logInCompleted", response);
  },

  async signUpAnonymous({ commit, state }) {
    //response = Bearer Token
    let model = {
      countryCode: state.countryCode,
      language: state.language,
    };
    const response = await state.api.signUpAnonymous(model);

    commit("signUpCompleted", response);
  },

  async changePassword({ state }, { currentPassword, newPassword }) {
    let model = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      language: state.language,
    };

    await state.api.changePassword(model);
  },

  async forgotPassword({ state }, email) {
    await state.api.forgotPassword(email, state.language);
  },

  async updateCustomerInfo(
    { commit, state },
    {
      firstName,
      middleName,
      lastName,
      phone,
      email,
      dateOfBirth,
      countryCode,
      password,
      language,
    }
  ) {
    let model = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      phone: phone,
      email: email,
      password: password,
      dateOfBirth: dateOfBirth,
      countryCode: countryCode,
      language: language,
    };

    await state.api.updateCustomerInfo(model);

    commit("updateCustomerInfoCompleted", model);
  },

  async updateCountry({ commit, state }, countryCode) {
    await state.api.updateCountry(countryCode);

    commit("updateCountryCompleted", countryCode);
  },

  async updateCurrency({ commit, state }, currencyCode) {
    await state.api.updateCurrency(currencyCode);

    commit("updateCurrencyCompleted", currencyCode);
  },

  async loadCustomerInfo({ commit, state }) {
    const response = await state.api.customerInfo();

    commit("loadCustomerInfoCompleted", response);
  },

  async confirmResetPassword({ state }, { userId, token, password }) {
    let model = {
      userId: userId,
      token: token,
      password: password,
      language: state.language,
    };

    await state.api.confirmReset(model);
  },
  async changeLanguage({ commit, state }, language) {
    
   
    let customerInfo = state.customerInfo;

    let model = {
      firstName:  customerInfo.firstName,
      middleName: customerInfo.middleName,
      lastName: customerInfo.lastName,
      phone: customerInfo.phone,
      email: customerInfo.email,
      password: customerInfo.password,
      dateOfBirth: customerInfo.dateOfBirth,
      countryCode: customerInfo.countryCode,
      language: language,
    };

    await state.api.updateCustomerInfo(model);

    commit("changeLanguageCompleted", language);
  },
  //async 
  async signUpWithShareCode(
    { commit, state },
    {
      firstName,
      lastName,
      phone,
      email,
      countryCode,
      password,
      sharerCustomerId,
      otp
    }
  ) {
    let model = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      password: password,
      countryCode: countryCode,
      language: state.language,
      sharerCustomerId,
      otp
    };

    //response = Bearer Token
    const response = await state.api.signUpWithShareCode(model);

    commit("signUpCompleted", response);
  },

  async oneTimePassword({ state },  phone) {
    let model = {
      phone
    };
    console.log(model);
    await state.api.oneTimePassword(model);
  },

  updateSelectedCountry({ commit, state }, country){
    commit("updateSelectedCountryCompleted", country);
  },
  

  loadCurrency({commit, state}){

    commit("loadCurrencyCompleted");
  },

  logOut({ commit }) {
    commit("logOutCompleted");
  },
};

// Mutations
const mutations = {
  signUpCompleted(state, bearerToken) {
    window.localStorage.setItem("token", bearerToken.token);
    window.localStorage.setItem("isRegistered", bearerToken.isRegistered);
    state.api.instance.defaults.headers.common = {
      Authorization: `Bearer ${bearerToken.token}`,
    };
    state.isRegistered = bearerToken.isRegistered;
  },

  logInCompleted(state, bearerToken) {
    //console.log('signUpCompleted');
    window.localStorage.setItem("token", bearerToken.token);
    window.localStorage.setItem("isRegistered", bearerToken.isRegistered);
    //console.log(`logInCompleted token:${bearerToken.token}`);
    //console.log('signUpCompleted - token');
    state.api.instance.defaults.headers.common = {
      Authorization: `Bearer ${bearerToken.token}`,
    };
    state.isRegistered = bearerToken.isRegistered;
  },

  updateCustomerInfoCompleted(state, customerInfo) {
    state.customerInfo.name = customerInfo.name;
    state.customerInfo.phone = customerInfo.phone;
    window.localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
  },

  updateCountryCompleted(state, countryCode) {
    state.countryCode = countryCode;
    window.localStorage.setItem("countryCode", countryCode);
  },
  
  updateSelectedCountryCompleted(state, country) {
    state.country = country;
    window.localStorage.setItem("country", JSON.stringify(country));
  },
  
  updateCurrencyCompleted(state, currencyCode) {
   
    state.currencyCode = currencyCode;
    window.localStorage.setItem("currencyCode", currencyCode);
  },

  loadCustomerInfoCompleted(state, customerInfo) {
    state.customerInfo = customerInfo;
    window.localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
  },

  changeLanguageCompleted(state, language) {
    state.language = language;
    window.localStorage.setItem("language", language);
  },

  logOutCompleted(state) {
    window.localStorage.clear();
    state.api.instance.defaults.headers.common = { Authorization: `Bearer 1` };
  },

  loadCurrencyCompleted(state){
    let currencyCode = state.currencyCode;
    let catalog = JSON.parse( window.localStorage.getItem("catalog"));
    let currency = catalog.currencies.find(c => c.currencyCode == currencyCode);
    if(currency){
      state.currency = currency;
      window.localStorage.setItem("currency", JSON.stringify(currency));
    }
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  loadToken(state) {
    let token = window.localStorage.getItem("token") || "";
    state.api.instance.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
