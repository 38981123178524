<template>
  <div>
    <v-card flat>
      <ProfileComponent v-if="isRegistered" />
      <LogInComponent v-else />
    </v-card>
  </div>
</template>

<script>
import ProfileComponent from "@/components/ProfileComponent";
import LogInComponent from "@/components/LogInComponent";
import { mapGetters } from "vuex";
export default {
  name: "AccountComponent",
  components: { LogInComponent, ProfileComponent },
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      countryCode: "countryCode",
    }),

    ...mapGetters("Catalog", {
      countries: "countries",
    }),
  },
  data: () => ({
    localizedText: {
      en: {
        username: "Username",
        password: "Password",
        signIn: "Sign In",
        signUp: "Sign Up",
        firstName: "First name",
        lastName: "Last name",
        middleName: "Middle name",
        email: "Email",
        phone: "Phone",
        forgotPassword: "Forgot password?",
      },
      ar: {
        username: "اسم المستخدم",
        password: "كلمة المرور",
        signIn: "تسجيل الدخول",
        signUp: "انشاء حساب",
        firstName: "الاسم الأول",
        lastName: "اللقب(الاسم الأخير)",
        middleName: "الاسم االثاني",
        email: "الايميل",
        phone: "التلفون",
        forgotPassword: "نسيت كلمة المرور؟",
      },
    },
    isSigningIn: false,
    isSigningUp: false,
    signInModel: { username: "", password: "", showPassword: false },
    signUpModel: {
      username: "",
      password: "",
      showPassword: false,
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
    },
    country: {},
  }),

  filters: {},

  methods: {
    signIn: async function () {
      let model = {
        userName: this.signInModel.username,
        password: this.signInModel.password,
      };
      this.isSigningIn = true;
      await this.$store.dispatch("Account/logIn", model);
      this.isSigningIn = false;
      await this.refreshData();
    },

    signUp: async function () {
      let model = {
        firstName: this.signUpModel.firstName,
        middleName: this.signUpModel.middleName,
        lastName: this.signUpModel.lastName,
        phone: this.signUpModel.phone,
        email: this.signUpModel.username,
        countryCode: this.countryCode,
        password: this.signUpModel.password,
      };

      this.isSigningUp = true;
      await this.$store.dispatch("Account/signUp", model);
      this.isSigningUp = false;
      await this.refreshData();
    },

    forgotPassword: function () {},

    loadCountries: async function () {
      await this.$store.dispatch("Catalog/loadCountries");
    },

    refreshData: async function () {
      this.$store.dispatch("loadToken");
      await this.$store.dispatch("Cart/loadCart", this.language);
      await this.$store.dispatch("Catalog/loadCatalog", this.language);
      await this.$store.dispatch("Account/loadCustomerInfo");
    },
  },

  mounted: async function () {
    await this.loadCountries();
    this.country = this.countries.find((c) => c.code == this.countryCode);
  },
};
</script>

<style>
</style>