import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/category/:outlineId',
    name: 'Category',
    props: r => ({ outlineId: r.params.outlineId }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Category" */ '@/views/Category.vue')
  }
  ,
  {
    path: '/item/:id',
    name: 'Item',
    props: r => ({ id: Number.parseInt( r.params.id, 0) }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Category" */ '@/views/Item.vue')
  }
  ,
  {
    path: '/giveawayitem/:id',
    name: 'GiveawayItem',
    props: r => ({ id: Number.parseInt( r.params.id, 0) }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Category" */ '@/views/GiveawayItem.vue')
  }
  ,
  {
    path: '/myorders/',
    name: 'MyOrders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Orders" */ '@/views/MyOrders.vue')
  }
  ,
  {
    path: '/orderdetail/:id',
    name: 'OrderDetail',
    props: r => ({ id: Number.parseInt( r.params.id, 0) }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Orders" */ '@/views/OrderDetail.vue')
  }
  ,
  {
    path: '/myaddressbook/',
    name: 'MyAddressBook',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Address" */ '@/views/MyAddressBook.vue')
  }
  ,
  {
    path: '/checkout/',
    name: 'Checkout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Cart" */ '@/views/Checkout.vue')
  }
  ,
  {
    path: '/orderresult',
    name: 'OrderResult',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Orders" */ '@/views/OrderResult.vue')
  }
  ,
  {
    path: '/wallet',
    name: 'Wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Orders" */ '@/views/MyWallet.vue')
  } ,
  {
    path: '/signup',
    name: 'Signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Orders" */ '@/views/SignUp.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
