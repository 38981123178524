<template>
  <div>
    <v-card>
      <v-card-title>
        {{ localizedText[language].chooseCurrency }}
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selectedCurrency"
          :items="items"
          outlined
          dense
          :label="localizedText[language].chooseCurrency"
          item-text="name"
          item-value="currencyCode"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="isLoading" dark block @click="save">
          {{ localizedText[language].save }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CurrencyPickerComponent",
  data: () => ({
    isLoading: false,
    selectedCurrency: "KWD",
    items: [],
    localizedText: {
      en: {
        chooseCurrency: "Choose currency",
        save: "Save",
      },
      ar: {
        chooseCurrency: "اختر العملة",
        save: "حفظ",
      },
    },
  }),
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
    }),
    ...mapGetters("Catalog", {
      catalog: "catalog",
    }),
  },

  methods: {
    save: async function () {
      this.isLoading = true;
      await this.$store.dispatch(
        "Account/updateCurrency",
        this.selectedCurrency
      );
      this.isLoading = false;
      this.$emit("currency-selected");
    },
  },

  mounted() {
    this.selectedCurrency = this.currencyCode;

    this.items = this.catalog.currencies;
  },
};
</script>

<style>
</style>